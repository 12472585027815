"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const AppButton_vue_1 = require("@/client/components/common/AppButton.vue");
const Payment_1 = require("@/common/inputs/Payment");
const Spendable_1 = require("@/common/inputs/Spendable");
const Card_vue_1 = require("@/client/components/card/Card.vue");
const ClientCardManifest_1 = require("@/client/cards/ClientCardManifest");
const PaymentUnit_vue_1 = require("@/client/components/PaymentUnit.vue");
const CardOrderStorage_1 = require("@/client/utils/CardOrderStorage");
const PaymentWidgetMixin_1 = require("@/client/mixins/PaymentWidgetMixin");
const PreferencesManager_1 = require("@/client/utils/PreferencesManager");
const Tag_1 = require("@/common/cards/Tag");
const Units_1 = require("@/common/Units");
const CardName_1 = require("@/common/cards/CardName");
const WarningsComponent_vue_1 = require("@/client/components/WarningsComponent.vue");
exports.default = vue_1.default.extend({
    name: 'SelectProjectCardToPlay',
    props: {
        playerView: {
            type: Object,
        },
        playerinput: {
            type: Object,
        },
        onsave: {
            type: Function,
        },
        showsave: {
            type: Boolean,
        },
        showtitle: {
            type: Boolean,
        },
    },
    computed: {
        ...PaymentWidgetMixin_1.PaymentWidgetMixin.computed,
        thisPlayer() {
            return this.playerView.thisPlayer;
        },
        SPENDABLE_RESOURCES() {
            return [
                'steel',
                'titanium',
                'heat',
                'plants',
                'microbes',
                'floaters',
                'lunaArchivesScience',
                'seeds',
                'graphene',
                'megaCredits',
                'corruption',
            ];
        },
    },
    data() {
        let card;
        let cards = [];
        if ((this.playerinput?.cards?.length ?? 0) > 0) {
            cards = CardOrderStorage_1.CardOrderStorage.getOrdered(CardOrderStorage_1.CardOrderStorage.getCardOrder(this.playerView.id), this.playerinput.cards);
            card = cards[0];
        }
        if (card === undefined) {
            throw new Error('no card provided in player input');
        }
        return {
            cardName: card.name,
            card: card,
            reserveUnits: card.reserveUnits ?? Units_1.Units.EMPTY,
            cards: cards,
            cost: 0,
            tags: [],
            payment: { ...Payment_1.Payment.EMPTY },
            warning: undefined,
            available: Units_1.Units.of({}),
        };
    },
    components: {
        Card: Card_vue_1.default,
        AppButton: AppButton_vue_1.default,
        PaymentUnitComponent: PaymentUnit_vue_1.default,
        WarningsComponent: WarningsComponent_vue_1.default,
    },
    mounted() {
        vue_1.default.nextTick(() => {
            this.card = this.getCard();
            this.cost = this.card.calculatedCost ?? 0;
            this.tags = this.getCardTags(),
                this.reserveUnits = this.card.reserveUnits ?? Units_1.Units.EMPTY;
            this.setDefaultValues();
        });
    },
    methods: {
        ...PaymentWidgetMixin_1.PaymentWidgetMixin.methods,
        getCard() {
            const card = this.cards.find((c) => c.name === this.cardName);
            if (card === undefined) {
                throw new Error(`card not found ${this.cardName}`);
            }
            return card;
        },
        getCardTags() {
            return (0, ClientCardManifest_1.getCardOrThrow)(this.cardName).tags;
        },
        setDefaultValues() {
            function deductUnits(availableUnits, unitValue, overpay = true) {
                if (availableUnits === undefined || availableUnits === 0) {
                    return 0;
                }
                const _tmp = megacreditBalance / unitValue;
                const balanceAsUnits = overpay ? Math.ceil(_tmp) : Math.floor(_tmp);
                const contributingUnits = Math.min(availableUnits, balanceAsUnits);
                megacreditBalance -= contributingUnits * unitValue;
                return contributingUnits;
            }
            function saveOverspendingUnits(spendingUnits, unitValue) {
                if (spendingUnits === undefined || spendingUnits === 0 || megacreditBalance === 0) {
                    return 0;
                }
                const overSpendingAsUnits = Math.floor(Math.abs(megacreditBalance) / unitValue);
                const toSaveUnits = Math.min(spendingUnits, overSpendingAsUnits);
                megacreditBalance += toSaveUnits * unitValue;
                return toSaveUnits;
            }
            for (const target of Spendable_1.SPENDABLE_RESOURCES) {
                this.payment[target] = 0;
            }
            let megacreditBalance = this.cost;
            for (const unit of ['seeds', 'lunaArchivesScience', 'graphene']) {
                if (megacreditBalance > 0 && this.canUse(unit)) {
                    this.payment[unit] = deductUnits(this.getAvailableUnits(unit), this.getResourceRate(unit), false);
                }
            }
            this.payment.megaCredits = Math.max(0, Math.min(this.thisPlayer.megaCredits, megacreditBalance));
            megacreditBalance = Math.max(megacreditBalance - this.thisPlayer.megaCredits, 0);
            for (const unit of ['microbes', 'floaters', 'corruption']) {
                if (megacreditBalance > 0 && this.canUse(unit)) {
                    this.payment[unit] = deductUnits(this.getAvailableUnits(unit), this.getResourceRate(unit));
                }
            }
            this.available.steel = Math.max(this.thisPlayer.steel - this.reserveUnits.steel, 0);
            if (megacreditBalance > 0 && this.canUse('steel')) {
                this.payment.steel = deductUnits(this.available.steel, this.getResourceRate('steel'), true);
            }
            this.available.titanium = Math.max(this.thisPlayer.titanium - this.reserveUnits.titanium, 0);
            if (megacreditBalance > 0 && (this.canUse('titanium') || this.canUseLunaTradeFederationTitanium())) {
                this.payment.titanium = deductUnits(this.available.titanium, this.getResourceRate('titanium'), true);
            }
            this.available.heat = Math.max(this.availableHeat() - this.reserveUnits.heat, 0);
            if (megacreditBalance > 0 && this.canUse('heat')) {
                this.payment.heat = deductUnits(this.available.heat, this.getResourceRate('heat'));
            }
            this.available.plants = Math.max(this.thisPlayer.plants - this.reserveUnits.plants, 0);
            if (megacreditBalance > 0 && this.canUse('plants')) {
                this.payment.plants = deductUnits(this.available.plants, this.getResourceRate('plants'));
            }
            if (megacreditBalance < 0) {
                for (const key of [
                    'steel',
                    'plants',
                    'floaters',
                    'microbes',
                    'seeds',
                    'graphene',
                    'lunaArchivesScience',
                    'corruption',
                    'megaCredits'
                ]) {
                    this.payment[key] -= saveOverspendingUnits(this.payment[key], this.getResourceRate(key));
                }
            }
        },
        canUseTitaniumRegularly() {
            return this.tags.includes(Tag_1.Tag.SPACE) ||
                this.thisPlayer.lastCardPlayed === CardName_1.CardName.LAST_RESORT_INGENUITY;
        },
        cardCanUse(unit) {
            switch (unit) {
                case 'megaCredits':
                    return true;
                case 'heat':
                    return this.playerinput.paymentOptions.heat === true;
                case 'steel':
                    return this.tags.includes(Tag_1.Tag.BUILDING) ||
                        this.thisPlayer.lastCardPlayed === CardName_1.CardName.LAST_RESORT_INGENUITY;
                case 'titanium':
                    return this.canUseTitaniumRegularly() ||
                        this.playerinput.paymentOptions.lunaTradeFederationTitanium === true;
                case 'plants':
                    return this.tags.includes(Tag_1.Tag.BUILDING) && this.playerinput.paymentOptions.plants === true;
                case 'microbes':
                    return this.tags.includes(Tag_1.Tag.PLANT);
                case 'floaters':
                    return this.tags.includes(Tag_1.Tag.VENUS);
                case 'lunaArchivesScience':
                    return this.tags.includes(Tag_1.Tag.MOON);
                case 'seeds':
                    return this.tags.includes(Tag_1.Tag.PLANT) ||
                        this.card.name === CardName_1.CardName.GREENERY_STANDARD_PROJECT;
                case 'graphene':
                    return this.tags.includes(Tag_1.Tag.SPACE) ||
                        this.tags.includes(Tag_1.Tag.CITY);
                case 'corruption':
                    return this.tags.includes(Tag_1.Tag.EARTH) && this.playerinput.paymentOptions.corruption === true;
                default:
                    throw new Error('Unknown unit ' + unit);
            }
        },
        canUse(unit) {
            if (!this.hasUnits(unit)) {
                return false;
            }
            if (this.card === undefined) {
                return false;
            }
            return this.cardCanUse(unit);
        },
        canUseLunaTradeFederationTitanium() {
            return this.playerinput.paymentOptions.lunaTradeFederationTitanium === true;
        },
        cardChanged() {
            this.card = this.getCard();
            this.cost = this.card.calculatedCost || 0;
            this.tags = this.getCardTags();
            this.reserveUnits = this.card.reserveUnits ?? Units_1.Units.EMPTY;
            this.setDefaultValues();
        },
        getTitaniumResourceRate() {
            const titaniumValue = this.asModel().playerView.thisPlayer.titaniumValue;
            if (this.canUseTitaniumRegularly()) {
                return titaniumValue;
            }
            return titaniumValue - 1;
        },
        hasWarning() {
            return this.warning !== undefined;
        },
        selectedCardHasWarning() {
            return this.card !== undefined && this.card.warning !== undefined;
        },
        showReserveWarning(unit) {
            switch (unit) {
                case 'titanium':
                    return this.reserveUnits.titanium > 0 && (this.canUse('titanium') || this.canUseLunaTradeFederationTitanium());
                case 'steel':
                case 'heat':
                case 'plants':
                    return this.reserveUnits[unit] > 0 && this.canUse(unit);
            }
            return false;
        },
        saveData() {
            let totalSpent = 0;
            for (const target of Spendable_1.SPENDABLE_RESOURCES) {
                totalSpent += this.payment[target] * this.getResourceRate(target);
            }
            for (const target of Spendable_1.SPENDABLE_RESOURCES) {
                if (this.payment[target] > this.getAvailableUnits(target)) {
                    this.warning = `You do not have enough ${target}`;
                    return;
                }
            }
            if (totalSpent < this.cost) {
                this.warning = 'Haven\'t spent enough';
                return;
            }
            if (totalSpent > this.cost) {
                const diff = totalSpent - this.cost;
                for (const target of Spendable_1.SPENDABLE_RESOURCES) {
                    if (this.payment[target] && diff >= this.getResourceRate(target)) {
                        this.warning = `You cannot overspend ${target}`;
                        return;
                    }
                }
            }
            const showAlert = (0, PreferencesManager_1.getPreferences)().show_alerts;
            if (totalSpent > this.cost && showAlert) {
                const diff = totalSpent - this.cost;
                if (confirm('Warning: You are overpaying by ' + diff + ' M€')) {
                    this.onsave({
                        type: 'projectCard',
                        card: this.card.name,
                        payment: this.payment,
                    });
                }
                else {
                    this.warning = 'Please adjust payment amount';
                    return;
                }
            }
            else {
                this.onsave({
                    type: 'projectCard',
                    card: this.card.name,
                    payment: this.payment,
                });
            }
        },
    },
});
