"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UNDERWORLDEXPANSION = exports.STARWARSEXPANSION = exports.CEOEXTENSION = exports.PATHFINDERSEXPANSION = exports.MOONEXPANSION = exports.ARESEXTENSION = exports.COMMUNITYCARDSOPTION = exports.PROMOCARDSOPTION = exports.TURMOIL = exports.COLONIES = exports.VENUSNEXT = exports.PRELUDE2EXPANSION = exports.PRELUDE = exports.CORPORATEERA = exports.OLD_BANNED_CARDS = exports.INCLUDED_CARDS = exports.BANNED_CARDS = exports.CUSTOM_PRELUDES = exports.OLD_CUSTOM_COLONIES = exports.CUSTOM_COLONIES = exports.OLD_CUSTOM_CORPORATIONS = exports.CUSTOM_CORPORATIONS = void 0;
exports.CUSTOM_CORPORATIONS = 'customCorporations';
exports.OLD_CUSTOM_CORPORATIONS = 'customCorporationsList';
exports.CUSTOM_COLONIES = 'customColonies';
exports.OLD_CUSTOM_COLONIES = 'customColoniesList';
exports.CUSTOM_PRELUDES = 'customPreludes';
exports.BANNED_CARDS = 'bannedCards';
exports.INCLUDED_CARDS = 'includedCards';
exports.OLD_BANNED_CARDS = 'cardsBlackList';
exports.CORPORATEERA = 'corporateEra';
exports.PRELUDE = 'prelude';
exports.PRELUDE2EXPANSION = 'prelude2Expansion';
exports.VENUSNEXT = 'venusNext';
exports.COLONIES = 'colonies';
exports.TURMOIL = 'turmoil';
exports.PROMOCARDSOPTION = 'promoCardsOption';
exports.COMMUNITYCARDSOPTION = 'communityCardsOption';
exports.ARESEXTENSION = 'aresExtension';
exports.MOONEXPANSION = 'moonExpansion';
exports.PATHFINDERSEXPANSION = 'pathfindersExpansion';
exports.CEOEXTENSION = 'ceoExtension';
exports.STARWARSEXPANSION = 'starWarsExpansion';
exports.UNDERWORLDEXPANSION = 'underworldExpansion';
