"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const i18n_1 = require("@/client/directives/i18n");
exports.default = {
    install: (Vue) => {
        Vue.prototype.$t = i18n_1.$t;
        Vue.directive('i18n', {
            inserted: (el, binding) => {
                el.setAttribute('tm-has-i18n', 'true');
                (0, i18n_1.translateTextNode)(el, binding);
            },
            componentUpdated: i18n_1.translateTextNode,
        });
    },
};
